import { toast } from "react-toastify";

const initialState = {
  loading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "MAKE_BOOKING_LOADING":
      return { ...state, loading: payload };
    case "MAKE_BOOKING_SUCCESS":
      toast.success("Réservation éffectuée!");
      return { ...state, loading: false };
    case "MAKE_BOOKING_ERROR":
      toast.error("Erreur lors de la réservation.");
      return { ...state, loading: false };

    default:
      return state;
  }
};
