import { Grid, Typography } from '@mui/material'
import React from 'react'
import Breadcrumb from '../../components/includes/Breadcrumb'

const Mentions = () => {
    return (
        <div>
            <Breadcrumb title="Mentions légales" />
            <Grid sx={{ p: 5 }} container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h4'>Informations générales</Typography>
                    <Typography sx={{ my: 2 }}>
                        Le présent site Internet est utilisé par la société suivante :<br />

                        <b>Dénomination sociale : </b> Hashen <br />
                        <b>Forme juridique : </b>  Société à Responsabilité Limitée Unipersonnel (SARLU)<br />
                        <b>Siège :</b> 44 avenue de la démocratie, Kinshasa - Gombe, République Démocratique du Congo <br />
                        <b>RCCM : </b>CD/KNG/RCCM/22-B-01182 <br />
                        <b>ID Nat: </b>01-F4300-N04095C<br />
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h4'>Directeur de publication</Typography>
                    <Typography sx={{ my: 2 }}>
                        Fady KOYABAMBI, Gérant de Hashen
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h4'>Hébergement</Typography>
                    <Typography sx={{ my: 2 }}>
                        LWS (Ligne Web Service), RCS Paris B 851 993 683 00024
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default Mentions
