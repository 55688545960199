import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import userReducer from "./userReducer";
import rentalReducer from "./rentalReducer";
import marketReducer from "./marketReducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  rental: rentalReducer,
  market: marketReducer,
});
